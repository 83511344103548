<template>
  <div class="cards p-5">
    <!--for home-->
    <div class="card" @click="move(styles[0], 'left')" v-bind:class="[styles[0]]">
      <slot name="left"/>
    </div>
    <div class="card" @click="move(styles[1], 'middle')" v-bind:class="[styles[1]]">
      <slot name="middle"/>
    </div>
    <div class="card" @click="move(styles[2], 'right')" v-bind:class="[styles[2]]">
      <slot name="right"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Testimonial",
    data () {
      return {
        styles: ['card-left-to-center', 'card-right-to-left', 'card-center-to-right']
      }
    },
    methods: {
      move (key, slotName) {
        var cr = key.split("-")[3];
        var dr = {
          left:{
            'left': 'right',
            'center': 'left',
            'right': 'center'
          },
          right: {
            'left': 'center',
            'center': 'right',
            'right': 'left'
          }
        }[cr]
        if (dr) {
          this.styles = this.styles.map(style => {
            var fr = style.split("-")[3];
            var to = dr[fr]
            return `card-${fr}-to-${to}`
          });
          this.$emit('slideMove', cr, slotName)
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  $card-perspective: 500px;

  $card-horizontal: (
      "left": -400px,
      "center": 0,
      "right": 400px,
  );

  $card-depth: (
      "left": -200px,
      "center": 0,
      "right": -200px,
  );

  $card-opacity: (
      "left": 0.5,
      "center": 1.0,
      "right": 0.5,
  );

  $card-z-index: (
      "left": 1,
      "center": 2,
      "right": 1,
  );

  @mixin move-animation($from, $to) {
    $name: move-#{unique-id()};

    @keyframes #{$name} {
      0% {
        transform: perspective($card-perspective) translate3d(map-get($card-horizontal, $from), 0, map-get($card-depth, $from));
        opacity: map-get($card-opacity, $from);
        z-index: map-get($card-z-index, $from);
      }
      100% {
        transform: perspective($card-perspective) translate3d(map-get($card-horizontal, $to), 0, map-get($card-depth, $to));
        opacity: map-get($card-opacity, $to);
        z-index: map-get($card-z-index, $to);
      }
    }

    animation-name: $name;
    animation-duration: 0.5s;
    transform: perspective($card-perspective) translate3d(map-get($card-horizontal, $to), 0, map-get($card-depth, $to));
    opacity: map-get($card-opacity, $to);
    z-index: map-get($card-z-index, $to);
  }

  .cards {
    min-height: 40rem;
    width: 100%;
    position: relative;
  }

  .card {
    position: absolute;
    display: block;
    cursor: pointer;
    left: 18%;
    animation-timing-function: ease-in;

  }

  $pos: left center right;
  @mixin move-list {
    @each $p1 in $pos {
      @each $p2 in $pos {
        .card-#{$p1}-to-#{$p2} {
          @include move-animation($p1, $p2)
        }
      }
    }
  }

  @include move-list;


</style>
